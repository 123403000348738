import { Connection } from '@/models';
import Pagination from '@/helpers/Pagination';
import Assert from '@/helpers/assert';

const getConnections = (soulApiRequest) => async (itemsPerPage, page, name) => {
  let params;

  if (!(itemsPerPage && itemsPerPage < 1)) {
    const queryParams = {
      per_page: `${itemsPerPage || 50}`
    };

    if (page > 0) {
      queryParams.page = `${(page || 1) - 1}`;
    }

    if (name) {
      queryParams.name = name;
    }
    params = new URLSearchParams(queryParams);
  }
  const response = await soulApiRequest({
    url: '/connections',
    method: 'get',
    params
  });

  const connections = Assert.isArray(response.data.connections, 'response.data.connections');

  const connectionList = connections.map((connection) => {
    return Connection(connection.id, connection.name, connection.strategy);
  });

  return {
    connections: connectionList,
    metadata: Pagination.extractMetadata(response.data, page, itemsPerPage)
  };
};

export default getConnections;
