/*
    Mutation naming convention.

    UPDATE_ - Update the complete module store.
    SET_ - Update a single property in the module store.
    RESET_ - Clear the module store back to its original values.

    NOTE: We could of reused mutation names across all modules
    e.g. RESET, UPDATE, ADD to avoid types like SESSION_RESET_SESSION,
    but I think in the long run it is better to explicity state each mutation than be generic.
*/
export const SET_PAGE_VIEW_TYPE = 'setPageViewType';
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const VIEW_TYPE_SET_PAGE_VIEW_TYPE = `view-type/${SET_PAGE_VIEW_TYPE}`;
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

export const SET_ORGANIZATION = 'setOrganization';
export const SET_ORGANIZATIONS = 'setOrganizations';
export const SET_CONNECTIONS = 'setConnections';
export const SET_SELECTED_CONNECTION = 'setSelectedConnection';

export const ORGANIZATION_SET_ORGANIZATION = `organization/${SET_ORGANIZATION}`;
export const ORGANIZATION_SET_CONNECTIONS = `organization/${SET_CONNECTIONS}`;
export const ORGANIZATION_SET_SELECTED_CONNECTION = `organization/${SET_SELECTED_CONNECTION}`;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

export const SET_USERS_BY_CONNECTION_NAME = 'setUsersByConnectionName';

export const DE_ASSIGN_ROLE_BY_USER_ID = 'deAssignRoleByUserId';
export const ASSIGN_ROLES_BY_USER_ID = 'assignRolesByUserId';

export const USERS_SET_USERS_BY_CONNECTION_NAME = `users/${SET_USERS_BY_CONNECTION_NAME}`;

export const SET_UPLOADED_USERS = 'setUploadedUsers';
export const RESET_UPLOADED_USERS = 'resetUploadedUsers';

export const SET_CREATED_UPLOADED_USERS = 'setCreatedUploadedUsers';
export const SET_FAILED_UPLOADED_USERS = 'setFailedUploadedUsers';
export const SET_SKIPPED_UPLOADED_USERS = 'setSkippedUploadedUsers';

export const SET_NAME = 'setName';
export const APP_SET_NAME = `app/${SET_NAME}`;

export const SET_APIVERSION = 'setApiVersion';
export const APP_SET_APIVERSION = `app/${SET_APIVERSION}`;
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

export const SET_ALL_CONNECTIONS = 'setConnections';

export const CONNECTIONS_SET_ALL_CONNECTIONS = `connections/${SET_ALL_CONNECTIONS}`;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
