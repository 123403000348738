<template>
  <div class="connection-list">
    <div class="connection-list-txt-search">
      <v-text-field
        id="connections-search-text-field"
        v-model="searchName"
        append-icon="mdi-magnify"
        label="Please search connections by full name"
        variant="underlined"
        color="primary"
        single-line
        hide-details
        @update:model-value="searchNameDebounce"
      ></v-text-field>
    </div>
    <v-data-table-server
      id="connections-table"
      class="connections-table"
      :row-props="connectionTableRow"
      :headers="connectionsView.headers"
      :items="connectionsView.items"
      :items-length="itemsLength"
      :loading="loadingBar"
      loading-text="Loading connections..."
      return-object
      dense
      :page="options.page"
      :items-per-page="options.itemsPerPage"
      :disable-sort="true"
      :items-per-page-options="[10, 25, 50, 100]"
      @update:page="onUpdatePage"
      @update:items-per-page="onUpdateItemsPerPage"
    >
    </v-data-table-server>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import debounce from 'lodash/debounce';

export default {
  name: 'ConnectionList',

  extends: CSBase,

  props: {
    queryOptions: {
      type: Object,
      default() {
        return { itemsPerPage: 10, page: 1 };
      }
    },
    refreshConnection: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:refreshConnection', 'update:queryOptions'],

  data() {
    return {
      searchName: '',
      options: {
        page: 1,
        itemsPerPage: 10
      }
    };
  },

  computed: {
    ...mapGetters({
      connectionsView: 'connections/connectionsView',
      itemsLength: 'connections/itemsLength'
    }),

    loadingBar() {
      return this.isLoading() ? 'primary' : false;
    },

    connectionTableRow() {
      return {
        class: 'connections-table-row'
      };
    }
  },

  watch: {
    async refreshConnection(newVal) {
      if (newVal) {
        await this.onRefreshConnections();
        this.$emit('update:refreshConnection', false);
      }
    },
    queryOptions: {
      async handler(newValue) {
        await this.fetchConnections(newValue);
        this.options = newValue;
      },
      deep: true,
      once: true
    }
  },

  methods: {
    searchNameDebounce: debounce(function _() {
      this.onSearchNameChange();
    }, 1000),

    async onSearchNameChange() {
      if (this.options.page > 1) {
        this.options.page = 1;
      }
      await this.onRefreshConnections();
    },

    updateOptions(page, itemsPerPage) {
      this.options.page = page;
      this.options.itemsPerPage = itemsPerPage;
      this.$emit('update:queryOptions', this.options);
    },

    async fetchConnections(options) {
      try {
        this.setLoading(true);

        const fetchOpt = {
          page: options.page,
          itemsPerPage: options.itemsPerPage
        };

        if (this.searchName) {
          fetchOpt.name = this.searchName;
        }
        await this.$store.dispatch('connections/fetchConnections', fetchOpt);
        if (this.itemsLength === 0) {
          this.updateOptions(1, 10);
        }
        this.clearLoading();
      } catch (e) {
        this.clearLoading();
        componentErrorHandler(this, e, undefined, false);
      }
    },

    async onUpdateItemsPerPage(itemsPerPage) {
      this.updateOptions(1, itemsPerPage);
      await this.onRefreshConnections();
    },

    async onUpdatePage(page) {
      this.updateOptions(page, this.options.itemsPerPage);
      await this.onRefreshConnections();
    },

    async onRefreshConnections() {
      const { itemsPerPage, page } = this.options;
      await this.fetchConnections({
        itemsPerPage,
        page
      });
    }
  }
};
</script>
<style scoped>
.connection-list-txt-search {
  opacity: var(--v-medium-emphasis-opacity);
  margin: 0 14px;
}
</style>
