import * as MutationTypes from '@/store/mutationTypes';

const mutations = {
  [MutationTypes.SET_ALL_CONNECTIONS](state, { connections, itemsLength }) {
    state.connections = connections;
    state.itemsLength = itemsLength;
  }
};

export default mutations;
