<template>
  <div>
    <cs-button
      id="create-connection-btn"
      primary
      label="Create Auth0 Connection"
      @click="showDialog = true"
    ></cs-button>
    <create-connection-dialog
      id="create-connection-dialog"
      v-model="showDialog"
      heading="Create Auth0 Connection"
      @connection-created="onConnectionCreated"
    ></create-connection-dialog>
  </div>
</template>

<script>
import CreateConnectionDialog from '@/components/CreateConnectionDialog';

export default {
  name: 'CreateConnection',

  components: { CreateConnectionDialog },

  emits: ['connection-created'],

  data() {
    return {
      showDialog: false
    };
  },

  methods: {
    onConnectionCreated() {
      this.$emit('connection-created');
    }
  }
};
</script>
