<template>
  <div class="site-config-editor">
    <h3 class="mt-5 mx-5">Site Configuration</h3>
    <v-container fluid class="mx-1">
      <v-combobox
        ref="search-app-url"
        v-model="appUrl"
        class="mb-5"
        :items="existingAppUrls"
        variant="underlined"
        color="primary"
        label="Application Url"
        :loading="loading"
        hint="Please type in URL for creating or updating a site"
        :persistent-hint="true"
        @update:model-value="onUpdateAppUrl"
        @update:menu="onUpdateMenu"
      />
      <div v-if="showConfig">
        <v-row>
          <v-col col="12">
            <v-card>
              <h5 :class="{ 'pa-4': true, 'text-red': isUpdate && !selectedOrganization?.id }">
                Selected Organization: {{ selectedOrganization?.displayName || '-' }}
                {{ currentOrganizationId }}
              </h5>
              <v-card-subtitle>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  variant="underlined"
                  color="primary"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-subtitle>
              <v-data-table
                id="organizations-table"
                :headers="organizationsTableHeaders"
                :items="organizationsView.items"
                :search="search"
                items-per-page="5"
                :items-per-page-options="[
                  { value: 5, title: '5' },
                  { value: 25, title: '25' },
                  { value: 50, title: '50' }
                ]"
                show-select
                select-strategy="single"
                color="primary"
              >
                <template #[`item.data-table-select`]="{ item }">
                  <v-radio-group
                    :model-value="radioSelectedOrganization"
                    class="center-affix mr-3"
                    hide-details
                    @update:model-value="selectOrganization"
                  >
                    <v-radio :id="`radio-${item.id}`" color="primary" :value="[item]"></v-radio>
                  </v-radio-group>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <h4 class="pt-5">Options</h4>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-checkbox
              id="prompt-on-login-enabled-checkbox"
              v-model="promptOnLoginEnabled"
              label="Prompt on Login"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              id="shared-device-enabled-checkbox"
              v-model="sharedDeviceEnabled"
              label="Show Shared Device Option"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              id="connection-buttons-enabled-checkbox"
              v-model="connectionButtonsEnabled"
              label="Connections Customization for Login"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <connections-selector
          v-if="connectionButtonsEnabled && selectedOrganization?.id"
          id="connections-selector"
          v-model="selectedConnections"
          :organization-id="selectedOrganization.id"
        />
        <div v-else style="height: 600px"></div>
      </div>
      <div v-if="showBtn" class="create-site-config-action">
        <v-spacer></v-spacer>
        <cs-button
          id="config-action-btn"
          primary
          :label="btnLabel"
          :disabled="isBtnDisabled"
          @click="onBtnClick"
        ></cs-button>
      </div>
    </v-container>
  </div>
  <site-config-editor-dialog
    id="site-config-editor-dialog"
    :model-value="showSiteConfigEditorDialog"
    :heading="dialogHeading"
    :app-url="appUrl"
    :organization="selectedOrganization"
    :prompt-on-login-enabled="promptOnLoginEnabled"
    :shared-device-enabled="sharedDeviceEnabled"
    :connection-buttons-enabled="connectionButtonsEnabled"
    :connection-names="selectedConnections"
    :is-update="isUpdate"
    @update:model-value="onDialogClose"
    @submitted="onSubmitted"
  />
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { soul } from '@/dependency-injection';
import { debounce, isEqual } from 'lodash';
import SiteConfigEditorDialog from '@/components/SiteConfigEditorDialog';
import { mapGetters } from 'vuex';
import ConnectionsSelector from '@/components/ConnectionsSelector';
import isValidAuth0ConfigAppUrl from '../helpers/isValidAuth0ConfigAppUrl';
import AssertError from '../helpers/AssertError';

export default {
  name: 'SiteConfigEditor',
  components: {
    'site-config-editor-dialog': SiteConfigEditorDialog,
    'connections-selector': ConnectionsSelector
  },
  extends: CSBase,

  data() {
    return {
      appUrl: '',
      existingAppUrls: [],
      loading: false,
      showConfig: false,
      search: null,
      radioSelectedOrganization: [],
      selectedOrganization: {},
      showBtn: false,
      auth0Config: null,
      promptOnLoginEnabled: true,
      sharedDeviceEnabled: false,
      connectionButtonsEnabled: false,
      selectedConnections: [],
      isMenuOpen: false,
      showSiteConfigEditorDialog: false,
      isUpdate: false,
      justUpdatedConfig: false
    };
  },

  computed: {
    ...mapGetters({
      organizationsView: 'organizations/organizationsView'
    }),

    organizationsTableHeaders() {
      return this.organizationsView.headers.filter((item) => item.key !== 'userDirectory');
    },

    btnLabel() {
      return this.isUpdate ? 'Update' : 'Link';
    },

    dialogHeading() {
      return this.isUpdate ? 'Update Site Configuration' : 'Link Organization to Application Url';
    },

    isBtnDisabled() {
      if (!this.isUpdate) {
        return Object.keys(this.selectedOrganization).length === 0;
      }
      if (!this.selectedOrganization?.id) {
        return true;
      }
      const validation = [
        this.promptOnLoginEnabled === !!this.auth0Config?.promptOnLoginEnabled,
        this.sharedDeviceEnabled === !!this.auth0Config?.sharedDeviceEnabled,
        this.connectionButtonsEnabled === !!this.auth0Config?.connectionButtonsEnabled,
        this.selectedOrganization?.id === this.auth0Config?.orgId,
        this.connectionButtonsEnabled
          ? isEqual(this.selectedConnections, this.auth0Config?.connectionNames)
          : true
      ];
      return validation.every((it) => !!it);
    },

    currentOrganizationId() {
      return this.selectedOrganization?.id || this.auth0Config?.orgId
        ? `(${this.selectedOrganization?.id || this.auth0Config?.orgId})`
        : '';
    }
  },

  watch: {
    auth0Config(val) {
      if (!val) {
        this.clearAlert();
        this.isUpdate = false;
        return;
      }
      this.isUpdate = true;
      if (!this.justUpdatedConfig) {
        this.showWarningAlert(
          'Please contact the client admin to inform all users to log out and re-login via the login page again to refresh their browser configuration after updating site configuration.'
        );
      }
    }
  },

  async mounted() {
    await this.initAppUrls();
    await this.$store.dispatch('organizations/fetchOrganizations');
  },

  methods: {
    async onSubmitted() {
      this.justUpdatedConfig = true;
      await this.initAppUrls();
      await this.getAuth0Config();
      this.mapAuth0Config();
    },
    onUpdateMenu(value) {
      this.isMenuOpen = value;
      if (!value) {
        this.searchAppUrlDebounce(this.appUrl);
      }
    },
    selectOrganization(value) {
      this.radioSelectedOrganization = value;
      const [selectedOrganization] = value;
      this.selectedOrganization = selectedOrganization;
    },
    async initAppUrls() {
      try {
        const allAppUrls = await soul.getAllAppUrls();
        // we need this logic to filter because we are sharing the same s3 bucket for development and develop environment
        // this help to prevent accessing app url config from different environment
        this.existingAppUrls = allAppUrls.filter((appUrl) => {
          const { port } = new URL(`http://${appUrl}`);
          return import.meta.env.VITE_AUTH0_DOMAIN.includes('local-dev') ? port : !port;
        });
      } catch (error) {
        this.showErrorAlert('An error occurred when updating the organization app urls');
      }
    },
    async getAuth0Config() {
      try {
        this.auth0Config = await soul.getAuth0ConfigByAppUrl(this.appUrl);
      } catch (error) {
        this.showErrorAlert('An error occurred when getting the client site configuration');
      }
    },
    restoreDefault() {
      this.showConfig = false;
      this.showBtn = false;
      this.auth0Config = null;
      this.promptOnLoginEnabled = true;
      this.sharedDeviceEnabled = false;
      this.connectionButtonsEnabled = false;
      this.selectedConnections = [];
      this.radioSelectedOrganization = [];
      this.selectedOrganization = {};
      this.justUpdatedConfig = false;
    },
    mapAuth0Config() {
      this.promptOnLoginEnabled = !!this.auth0Config?.promptOnLoginEnabled;
      this.sharedDeviceEnabled = !!this.auth0Config?.sharedDeviceEnabled;
      this.connectionButtonsEnabled = !!this.auth0Config?.connectionButtonsEnabled;
      this.selectedConnections = this.auth0Config?.connectionNames;
      const selectedOrganizations = this.organizationsView.items.filter(
        (item) => item.id === this.auth0Config?.orgId
      );
      this.radioSelectedOrganization = selectedOrganizations;
      this.selectedOrganization = selectedOrganizations?.[0] || {};
      if (!selectedOrganizations?.[0]) {
        this.showErrorAlert('Invalid organization id in existing auth0 configuration');
      }
    },
    onUpdateAppUrl(value) {
      this.appUrl = value;
      this.loading = true;
      this.restoreDefault();
      this.searchAppUrlDebounce(value);
    },
    extractHostFromInputUrl(input) {
      const normalizedUrl = !/^https?:\/\//i.test(input) ? `http://${input}` : input;
      const { host } = new URL(normalizedUrl);
      return host;
    },
    searchAppUrlDebounce: debounce(async function _(value) {
      this.clearAlert();
      if (this.isMenuOpen) {
        return;
      }
      if (!value) {
        this.loading = false;
        return;
      }
      try {
        const appUrl = this.extractHostFromInputUrl(value);
        if (!isValidAuth0ConfigAppUrl(appUrl)) {
          throw new AssertError('Invalid url');
        }
        this.appUrl = appUrl;
        if (this.existingAppUrls.includes(appUrl)) {
          await this.getAuth0Config();
          this.mapAuth0Config();
        }
        this.showConfig = true;
        this.showBtn = true;
      } catch (error) {
        this.showErrorAlert('You have entered an invalid URL');
        this.showBtn = false;
      }
      this.loading = false;
    }, 1000),
    onBtnClick() {
      this.showSiteConfigEditorDialog = true;
    },
    onDialogClose(val) {
      this.showSiteConfigEditorDialog = val;
    }
  }
};
</script>

<style scoped>
.create-site-config-action {
  display: flex;
}
</style>
