const Connections = {
  create: (connectionList) => {
    const connections = (connectionList || []).slice(0);

    connections.concatenate = (other) => {
      return Connections.create(connections.concat(other));
    };
    return connections;
  }
};

export default Connections;
