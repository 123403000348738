<template>
  <cs-form-dialog
    :id="id"
    :model-value="showDialog"
    :heading="heading"
    :primary-action="{
      label: 'Create',
      loading: isCreatingConnection
    }"
    @primary-click="validateAndCreateConnection"
    @update:model-value="onCloseDialog"
  >
    <template #cs-form-dialog-content>
      <v-form ref="createConnectionForm">
        <v-text-field
          v-model.trim="connectionName"
          label="Name"
          color="primary"
          variant="underlined"
          :rules="[
            validationRules.required('Connection Name'),
            validationRules.minMaxLength('Connection Name', 3, 35),
            validationRules.restrictConnectionName('Connection Name')
          ]"
          class="mt-3"
        >
        </v-text-field>
      </v-form>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { soul } from '@/dependency-injection';

export default {
  name: 'CreateConnectionDialog',

  components: {},
  extends: CSBase,

  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    validationRules: {
      type: Object,
      default() {
        return {
          required: (label) => (v) => !!v || `${label} is required`,
          minMaxLength: (label, min, max) => (v) =>
            (v && v.length <= max && v.length >= min) ||
            `${label} should be within ${min} and ${max} characters`,
          restrictConnectionName: (label) => (v) => {
            const isValidConnectionName = /^[a-z|0-9]+(?:-+[a-z|0-9]+)*$/.test(v);
            return (
              isValidConnectionName ||
              `${label} must start and end with a lowercase letter or number, may include hyphens, and no uppercase letters`
            );
          }
        };
      }
    }
  },

  emits: ['update:modelValue', 'connection-created'],

  data() {
    return {
      showDialog: this.modelValue,
      connectionName: '',
      isCreatingConnection: false
    };
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    onCloseDialog() {
      this.restoreDefault();
      this.$emit('update:modelValue', false);
    },

    restoreDefault() {
      this.$refs.createConnectionForm.reset();
      this.$refs.createConnectionForm.resetValidation();
    },

    async isValid() {
      const validation = await this.$refs.createConnectionForm.validate();
      return validation.valid;
    },

    async validateAndCreateConnection() {
      const valid = await this.isValid();
      if (!valid) return;
      try {
        this.isCreatingConnection = true;
        await soul.createConnectionByName(this.connectionName);
        this.isCreatingConnection = false;
        this.showSuccessAlert(`Connection ${this.connectionName} created successfully`);
        this.onCloseDialog();
        this.$emit('connection-created');
      } catch (err) {
        this.isCreatingConnection = false;
        if (err.name === 'Conflict') {
          this.showErrorAlert(`Connection ${this.connectionName} already exists`, true);
          return;
        }
        this.showErrorAlert(err.message, true);
      }
    }
  }
};
</script>
