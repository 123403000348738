<template>
  <v-card :id="id" ref="confirm-users-table-card" class="confirm-users-table-card">
    <v-card-subtitle v-if="showSearch" class="confirm-users-card-subtitle">
      <v-text-field
        :id="searchId"
        ref="search"
        v-model="search"
        append-icon="mdi-magnify"
        class="confirm-users-search"
        label="Search"
        min-width="250px"
        single-line
        hide-details
        color="primary"
        variant="underlined"
      ></v-text-field
    ></v-card-subtitle>
    <v-data-table
      :id="tableId"
      ref="confirm-users-table"
      class="confirm-users-table"
      :items="users"
      :headers="headers"
      :search="search"
    >
      <template #no-data>
        <span v-if="users.length === 0" class="table-no-data-text">No data available</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';

export default {
  name: 'ConfirmUsersTable',
  extends: CSBase,

  props: {
    searchId: { type: String, required: false, default: '' },
    tableId: { type: String, required: false, default: '' },
    users: {
      type: Array,
      required: true,
      default: undefined
    },

    headers: {
      type: Array,
      default: undefined,
      validator(val) {
        if (!Array.isArray(val)) {
          return false;
        }

        if (val.length === 0) {
          return true;
        }

        return val.every(
          (it) => typeof it.title === 'string' && typeof it.key === 'string' && it.title && it.key
        );
      }
    },

    showSearch: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  data() {
    return {
      id: this.$attrs.id,
      search: this.showSearch ? '' : undefined
    };
  },

  watch: {
    showSearch(val) {
      this.search = val ? '' : undefined;
    }
  },

  methods: {
    resetSearch() {
      if (this.showSearch) {
        this.$refs.search.reset();
      }
    }
  }
};
</script>
