import Assert from '@/helpers/assert';

const createOrganizationByName = (soulApiRequest) => async (name, displayName) => {
  Assert.isPresent(name, 'createOrganizationByName.name');
  Assert.isPresent(displayName, 'createOrganizationByName.displayName');

  const response = await soulApiRequest({
    url: `/organization`,
    method: 'post',
    data: {
      name,
      displayName
    }
  });

  return response.data;
};

export default createOrganizationByName;
