import { createStore, createLogger } from 'vuex';

import modules from './modules';

const debug = import.meta.env.NODE_ENV !== 'production' && import.meta.env.NODE_ENV !== 'testing';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
