<template>
  <div>
    <cs-button
      id="create-org-btn"
      primary
      label="Create Organization"
      @click="showDialog = true"
    ></cs-button>
    <create-organization-dialog
      id="create-org-dialog"
      v-model="showDialog"
      heading="Create Organization"
      @organization-created="onOrganizationCreated"
    ></create-organization-dialog>
  </div>
</template>

<script>
import CreateOrganizationDialog from './CreateOrganizationDialog';

export default {
  name: 'CreateOrganization',

  components: {
    'create-organization-dialog': CreateOrganizationDialog
  },

  emits: ['organization-created'],

  data() {
    return {
      showDialog: false
    };
  },

  methods: {
    onOrganizationCreated() {
      this.$emit('organization-created');
    }
  }
};
</script>
