import { createApp, h } from 'vue';
import { vuetify, translator } from '@complispace/cs-design-system';
import * as CSDesignSystem from '@complispace/cs-design-system';
import App from './App';
import router from './router';
import store from './store';
import Assert from './helpers/assert';

import '@complispace/cs-design-system/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/lib/codemirror.css';

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
    auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    appEnv: Assert.isPresent(import.meta.env.VITE_APP_ENV, 'VITE_APP_ENV')
  }
};

const app = createApp({
  render: () => h(App)
});

const reqRes = import.meta.glob('./resources/i18n/*.json', { eager: true });
translator.applyAppI18n(reqRes);

app.use(CSDesignSystem, options);
app.use(vuetify);
app.use(router);
app.use(store);
app.mount('#app');
