import Assert from '@/helpers/assert';

const createConnectionByName = (soulApiRequest) => async (name) => {
  Assert.isPresent(name, 'createConnectionByName.name');

  const response = await soulApiRequest({
    url: `/connection`,
    method: 'post',
    data: {
      name
    }
  });

  return response.data;
};

export default createConnectionByName;
