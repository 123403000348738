export const keys = ['id', 'name', 'strategy'];
export const headerTexts = ['Id', 'Name', 'Strategy'];

const getters = {
  connectionsView(state) {
    const { connections } = state;

    if (!connections.length) {
      return {
        headers: [],
        items: []
      };
    }
    const headers = keys.map((value, index) => {
      const title = headerTexts[index];
      const col = { title, key: value, sortable: false };
      return col;
    });
    return {
      headers,
      items: connections
    };
  },

  itemsLength(state) {
    return state.itemsLength;
  }
};

export default getters;
