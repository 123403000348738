<template>
  <v-select
    v-model="selectedValue"
    class="app-urls-select"
    :label="label"
    :items="items"
    :disabled="disabled || items.length === 0"
    :hint="selectedValue ? '' : hint"
    :rules="rules"
    :readonly="readonly"
    persistent-hint
    variant="underlined"
    color="primary"
    :menu-props="{ zIndex: 3000 }"
    @update:model-value="onChange"
  ></v-select>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';

export default {
  name: 'AppUrlSelector',
  extends: CSBase,
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },

    hint: { type: String, required: false, default: '' },

    label: {
      type: String,
      required: true,
      validator(value) {
        return typeof value === 'string' && value.length > 0;
      }
    },

    rules: {
      type: Array,
      default: () => []
    },

    readonly: Boolean,
    selected: { type: String, required: false, default: undefined }
  },

  emits: ['change'],

  data() {
    return {
      selectedValue: ''
    };
  },
  computed: {
    ...mapGetters({
      appUrls: 'organization/appUrlsView'
    }),

    items() {
      return Object.keys(this.appUrls);
    }
  },

  watch: {
    appUrls() {
      if (!this.disabled) {
        this.selectedValue = '';
        this.onChange(this.selectedValue);
      }
    }
  },

  mounted() {
    if (!this.selected && !this.disabled) {
      this.selectedValue = '';
      this.onChange(this.selectedValue);
    } else {
      this.selectedValue = this.selected;
    }
  },

  methods: {
    onChange(label) {
      let selected = '';
      if (label) {
        selected = this.appUrls[label];
      }
      this.$emit('change', selected);
    },

    reset() {
      if (this.selectedValue) {
        this.selectedValue = '';
        this.onChange(this.selectedValue);
      }
    }
  }
};
</script>

<style scoped>
.code-snippet {
  height: 400px;
}
</style>
