<template>
  <div :id="id" class="create-users">
    <v-tooltip id="create-users-tooltip" location="left">
      <template #activator="{ props }">
        <div
          v-bind="props"
          id="create-users-tooltip-wrapper"
          style="display: flex; align-items: start"
        >
          <cs-button
            id="uploaded-users-create-btn"
            :disabled="!canRunBulkAction"
            :primary="primary"
            label="Create Users"
            @click="onCreateUsersClick"
          ></cs-button>
        </div>
      </template>
      <span id="create-users-tooltip-text">At least one user must be selected.</span>
    </v-tooltip>

    <app-url-selector-dialog
      id="uploaded-users-redirect-dialog"
      ref="create-users-app-url-selector"
      v-model="showAppUrlDialog"
      confirm-label="Continue"
      cancel-label="Cancel"
      heading="Set Password Reset Redirect Url"
      hint="User will be navigated to this url after reset their password."
      label="Application Url"
      selector-id="uploaded-users-redirect-url-input"
      @app-url-confirm="onAppUrlConfirm"
      @app-url-cancel="onAppUrlCancel"
    ></app-url-selector-dialog>

    <progress-dialog
      id="uploaded-users-progress-dialog"
      cancel-btn-label="Cancel"
      heading="Creating Users"
      progress-bar-id="uploaded-users-progress-bar"
      :model-value="showProgressDialog"
      :progress-percent="progressPercent"
      @progress-cancel="onProgressDialogCancel"
    ></progress-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import AppUrlSelectorDialog from '@/components/AppUrlSelectorDialog';
import ProgressDialog from '@/components/ProgressDialog';

import BulkActionBase from '@/components/bulkActions/BulkActionBase';
import BulkActionName from '@/components/bulkActions/bulkActionName';
import componentErrorHandler from '@/helpers/componentErrorHandler';

export default {
  name: 'CreateUsers',

  components: {
    'app-url-selector-dialog': AppUrlSelectorDialog,
    'progress-dialog': ProgressDialog
  },
  extends: BulkActionBase,

  props: {
    connectionName: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },

    primary: {
      type: Boolean,
      default() {
        return false;
      }
    },

    selected: {
      type: Array,
      required: true
    }
  },

  emits: ['create-users-completed'],

  data() {
    return {
      id: this.$attrs.id,
      redirectUrl: '',
      showAppUrlDialog: false
    };
  },

  computed: {
    canRunBulkAction: {
      get() {
        if (this.disabled) {
          return false;
        }

        if (!this.isSelected) {
          return false;
        }

        if (this.isBulkActionInProgress) {
          return false;
        }

        if (this.progressPercent > 0) {
          return false;
        }

        return true;
      }
    },

    ...mapState({
      createdUsers: (state) => state.uploadedUsers.createdUploadedUsers,
      failedUsers: (state) => state.uploadedUsers.failedUploadedUsers
    }),

    ...mapGetters({
      appUrls: 'organization/appUrlsView'
    }),

    appUrlValues() {
      return Object.values(this.appUrls);
    },

    hasAppUrls() {
      return this.appUrlValues.length > 0;
    }
  },

  methods: {
    ...mapActions({
      setCreatedUsers: 'uploadedUsers/setCreatedUploadedUsers',
      setFailedUsers: 'uploadedUsers/setFailedUploadedUsers'
    }),

    onCreateUsersClick() {
      if (!this.isSelected) {
        componentErrorHandler(
          this,
          undefined,
          'At least one user must be selected to create users',
          false
        );
        return;
      }

      if (!this.hasAppUrls) {
        componentErrorHandler(
          this,
          undefined,
          'Please contact Complispace Admin to setup the app urls for the organization.',
          false
        );

        return;
      }

      this.showAppUrlDialog = true;
    },

    async runActionAndReport(user) {
      try {
        const p = await this.runAction(user);

        this.successfulBulkActionUsers.push(user);

        const createdUsers = this.createdUsers.concatenate(user);
        this.setCreatedUsers({
          createdUploadedUsers: createdUsers,
          connectionName: this.connectionName
        });

        return p;
      } catch (e) {
        this.failedBulkActionUsers.push(user);
        const failedUsers = this.failedUsers.concatenate(user);
        this.setFailedUsers({
          connectionName: this.connectionName,
          failedUploadedUsers: failedUsers
        });
      }

      return undefined;
    },

    async onAppUrlConfirm(appUrl) {
      this.redirectUrl = appUrl;
      this.showAppUrlDialog = false;
      this.resetAppUrlSelector();

      this.initBulkActionProgress(BulkActionName.CREATE_USERS);

      await this.selected.reduce(this.runActionOnUsers, undefined);

      this.toggleProgressDialog(false);
      this.setProgressPercent(0);

      const failedUsersLen = this.failedBulkActionUsers.length;
      const message = this.getBulkActionMessage();

      this.showBulkActionAlert(message, failedUsersLen);

      this.redirectUrl = '';
      const successfulUsersLen = this.successfulBulkActionUsers.length;
      this.clearBulkActionState();

      this.$emit('create-users-completed', { failedUsersLen, successfulUsersLen });
    },

    onAppUrlCancel() {
      this.redirectUrl = '';
      this.resetAppUrlSelector();

      this.showAppUrlDialog = false;
      this.isBulkActionInProgress = false;
    },

    resetAppUrlSelector() {
      this.$refs['create-users-app-url-selector'].reset();
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 1264px) {
  #uploaded-users-create-btn {
    min-width: 100%;
  }
}
</style>
